'use client';
import * as Sentry from '@sentry/nextjs';
import { Analytics } from 'app/lib/analytics';
import { usePathname } from 'app/navigation';
import { useSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import {
  PostHogProvider as _PostHogProvider,
  usePostHog,
} from 'posthog-js/react';
import { useEffect } from 'react';

const POSTHOG_EU_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_EU_API_KEY;
const POSTHOG_EU_HOST = process.env.NEXT_PUBLIC_POSTHOG_EU_HOST;
const isProduction = process.env.NODE_ENV === 'production';

const POSTHOG_PARAMS: Parameters<typeof posthog.init>[1] = {
  api_host: POSTHOG_EU_HOST,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
  on_xhr_error: (xhr) => {
    Sentry.captureException(xhr);
  },
  loaded: () => {
    if (!isProduction) {
      posthog.debug();
      posthog.opt_out_capturing();
    }
  },
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  capture_pageleave: true,
};

if (typeof window !== 'undefined') {
  if (POSTHOG_EU_API_KEY) {
    posthog.init(POSTHOG_EU_API_KEY, POSTHOG_PARAMS);
  }
}

function PostHogPageviewTracker() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = `${url}?${searchParams.toString()}`;
      }
      Analytics.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <_PostHogProvider client={posthog}>
      <PostHogPageviewTracker />
      {children}
    </_PostHogProvider>
  );
}
